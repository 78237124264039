// 布景顏色

$color-one: #312A8E; // 背景漸層色上
$color-two: #9264FF; // 一般按鈕
$color-three: #0E0019; // 背景漸層色下
$color-four: #552D74; // 按鈕 hover
$color-five: #730FBF; // 按鈕漸層左
$color-six: #EBAF23; // 按鈕漸層右
$color-seven: #785D8D; // header 漸層
$color-eight: #6452E8; // 日夜間 button
$color-nine: #EAD2FF; // 下拉選單背景色


// 登入 input outline

$color-input: #00FFD3;

// 表格相關（註：好像多數棄用了

$color-form-th: #d9d2d2;
$color-pop-background: #CCCCCC;
$color-form-border: #707070;
$color-link: #0044FF;

// 基礎色

$color-black: #000;
$color-white: #eeeeee; //注意，這不是純白
$color-pure-white: #fff;
$color-gray-light: #DBCBCB;
$color-gray-dark: #474747;

// dark-mode 顏色
$color-background-dark: #272727;
$color-td-dark: #404040;
$color-text-dark: #d1d5db;
$color-header-dark: #1D167F;
$color-title-dark: #D2BFFF;
