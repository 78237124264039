.btn {

  &,
  &:link,
  &:visited {
    border-radius: 2rem;
    background-color: $color-two;
    color: $color-white;
    font-size: 1.6rem;
    border: none;
    box-shadow: none;
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 .4rem .8rem rgba($color-black, .15);
    transform: translateY(-1px);
  }

  &:active {
    box-shadow: 0 .2rem .4rem rgba($color-black, .1);
    transform: translateY(1px);
  }


  // 登入按鈕
  &--login {

    &,
    &:link,
    &:visited {
      width: 100%;
      background-size: 200%;
      background-image: linear-gradient(75deg,
          $color-five,
          $color-six,
          $color-five,
          $color-six,
          $color-five,
        );
      padding: .7rem 1.5rem;
      font-size: 1.6rem;
      transition: .5s ease;

      animation: gradient 2s linear infinite;
    }

    &:hover,
    &:active {
      color: $color-white;
      transform: translateY(0);
    }
  }

  // nav 側欄選單
  &--menu {

    &,
    &:link,
    &:visited {
      width: 100%;
      padding: 1.2rem 2.4rem;
      background-color: rgba($color-white, .2);
      border-radius: .5rem 2.5rem 2.5rem .5rem;
      font-size: 1.8rem;
      text-align: left;
    }

    &:hover,
    &:active {
      background-image: linear-gradient(to right,
          $color-five,
          $color-six,
        );
      color: $color-white;
    }
  }

  // nav 下拉選單
  &--dropdown {

    &,
    &:link,
    &:visited {
      width: 90%;
      padding: .5rem 1.5rem;
      font-size: 1.8rem;
      border-radius: .5rem;
      background-color: rgba($color-white, .2);

      &:not(:last-child) {
        margin-bottom: .8rem;
      }
    }

    &:hover,
    &:active {
      background-color: $color-nine;
      color: $color-one;
    }
  }

  // main 推廣控管的頁籤
  &--tab {

    &,
    &:link,
    &:visited {
      background-color: $color-pop-background;
      color: $color-black;
      padding: .5rem 6rem;
    }

    &:hover {
      background-color: $color-seven;
      color: $color-white;
    }

  }

  // 總之就是各種 api 的按鈕

  &--request {

    &,
    &:link,
    &:visited {
      padding: .6rem 2rem;
    }

    &:hover,
    &:active {
      background-color: $color-four;
      color: $color-white;
    }

  }

  // 推廣控管 > 推廣成果的設計稿選錯色，將錯就錯多一個 search 按鈕

  &--search {

    &,
    &:link,
    &:visited {
      background-color: $color-one;
      padding: .3rem 5rem;
    }

    &:hover,
    &:active {
      background-color: $color-one;
      color: $color-white;
    }
  }

  &--all {
    width: 100%;
    background-color: $color-seven;
    padding: .3rem 3rem;
    margin-top: 1rem;
  }

}

@keyframes gradient {
  // background-position is based on the background-size. Make size bigger then the animation will work!

  0% {
    background-position: right top;
  }

  100% {
    background-position: left top;
  }
}
