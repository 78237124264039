// 其實放 styles.scss 比較合理，但不在這裡寫 padding: 0，body 左右就會被推擠，只好留著了
*,
*::after,
*::before {
  padding: 0;
}

// 蓋掉 bootstrap 預設 p 會推 mb-1
// login RWD 才發現這個莫名其妙的...

p {
  margin-bottom: 0;
}
