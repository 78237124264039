// import mdb-angular
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';

/* You can add global styles to this file, and also import other style files */
@import "../styling//abstracts/variable.scss";
@import "../styling//abstracts/mixins.scss";
@import "../styling/base/base.scss";
@import "../styling/utils/utils.scss";
@import "../styling/components/button.scss";


// --------border-box--------
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // outline: 1px solid rgba(28, 127, 181, 0.8);
}


// --------16 * 62.5% = 10--------
html {
  font-size: 62.5%;
}


// --------蓋掉 modal open 時自動添加的 padding-right: 15px & 字體設定--------
body {
  padding-right: 0 !important;
  font-family: 'Noto Sans TC', sans-serif;
}

// --------客製反白色--------
::selection {
  background-color: $color-two;
  color: $color-white;
}


// --------ScrollBar---------
// https://stackoverflow.com/questions/50817727/change-scrollbar-height

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track-piece {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: $color-gray-light;
  border-radius: 1rem;
}

// --------Input invalid--------
input.ng-invalid.ng-touched {
  border: 1px solid red;
}


// --------分頁----------

pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

// -------dark-mode 顏色---------

.dark-theme {

  mat-drawer-container {
    background-color: $color-background-dark !important;
  }

  .header {
    background-image: linear-gradient(to bottom,
        rgba($color-header-dark, 1) 0%,
        rgba($color-header-dark, .9) 3%,
        rgba($color-header-dark, .8) 10%,
        rgba($color-header-dark, .7) 20%,
        rgba($color-header-dark, .6) 30%,
        transparent 85%,
        transparent 100%,
      ) !important;
  }

  // 頁籤標題
  .page {
    &__decoration {
      background-color: $color-title-dark !important;
    }

    &__text {
      color: $color-title-dark !important;
    }
  }

  // 一般表格 （什麼的一般表格？）
  th {
    // color: $color-gray-dark !important;
  }

  th,
  td {
    background-color: $color-gray-dark !important;
    color: $color-gray-light !important;
  }


  // 帳號資訊的表格
  app-account {
    th {
      background-color: $color-background-dark !important;
      color: $color-text-dark !important;
    }

    td {
      background-color: $color-td-dark !important;
      color: $color-text-dark !important;
    }
  }

  // 表單輸入框
  .form input {
    background-color: $color-gray-dark !important;
    color: $color-text-dark !important;
  }

  select,
  option {
    background-color: $color-gray-dark !important;
    color: $color-text-dark !important;
  }

  // 無資料的文字顏色
  .noInfo {
    color: $color-gray-light !important;
  }

  // td 裡的網址
  td a {
    color: $color-gray-light !important;
  }

  // 分頁文字顏色
  .ngx-pagination a {
    color: $color-text-dark !important;

    &:hover {
      color: $color-black !important;
    }
  }

  // ~
  .wave {
    color: $color-gray-light !important;
  }

  // 日期搜索提示文字
  .tip {
    color: rgba($color-gray-light, .8) !important;
  }

  // searchInput
  .searchInput {
    input[type=text] {
      background-color: $color-gray-dark !important;
      color: $color-text-dark !important;
    }
  }
}

